import React, { useState, useEffect } from 'react';
import Logo from './assets/logo';
import ComingSoon from './assets/comingSoon';
import './App.css'; // Import the CSS file for animations
import { push as DBpush, ref as DBref, set as DBset } from "firebase/database";
import { db } from "./firebase";
import axios from "axios";
import Swal from "sweetalert2";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Check initially and whenever the window resizes
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const newContactRef = DBpush(DBref(db, 'kaktu/contacts'));

    try {
      await DBset(newContactRef, {
        email,
        time: new Date(Date.now()).toLocaleString(),
      });

      await axios.post(
        "https://svzt2ejd3hezga2nod5i3f62pe0kpgaj.lambda-url.eu-west-1.on.aws",
        { email }
      );

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        background: "#dcfce7",
        showConfirmButton: false,
        timer: 3000,
      });
      Toast.fire({
        icon: "success",
        title: "Your email has been sent successfully!",
      });

      setEmail(""); // Clear the input field
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    } finally {
      setIsSending(false);
    }
  };


  return (
    // lg:max-w-max lg:w-full lg:mx-auto lg:flex flex-col items-center lg:justify-center space-y-2
    <div className="h-screen overflow-hidden lg:w-full lg:mx-auto lg:flex flex-col lg:justify-center lg:max-w-[2500px]">
      <div className='bg-[#F6F6F6]  min-h-screen flex flex-col'>
        <div className="flex md:ml-28 justify-start sm:mb-20">
          <Logo />
        </div>
        <div className="flex flex-col pb-20  justify-center flex-grow space-y-2">
          <div className="text-start mb-16 space-y-0  md:space-y-2 md:ml-36 ml-8 mr-8">
            <div className='flex md:flex-row flex-col md:space-x-3'>
              <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-[55px] leading-[60.5px] md:text-5xl text-[#282828] font-bold uppercase`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                SOMETHING
              </h1>
              <div className='flex flex-row space-x-3'>
                <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-[55px] leading-[60.5px] md:text-5xl text-[#282828] font-bold uppercase`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                  AWESOME
                </h1>
                <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-[55px] leading-[60.5px] md:text-5xl text-[#282828] font-bold uppercase md:hidden`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                  IS
                </h1>
              </div>
            </div>
            <div className='flex flex-row md:space-x-3'>
              <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-[55px] leading-[60.5px] md:text-5xl text-[#282828] font-bold uppercase hidden md:block`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                IS
              </h1>
              <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-[55px] leading-[60.5px] text-[#282828] md:text-5xl font-bold uppercase`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                COMING
                <span className="dot-loader">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </span>
              </h1>
            </div>

            <p className="text-lg md:text-2xl font-medium text-[#514E49] pt-2 pb-10 md:pb-4">The search for a property, reinvented.</p>
            <div className="space-y-2">
              <form onSubmit={handleSubmit} className="flex flex-col md:flex-row md:space-x-2 md:pt-3 space-y-3 md:space-y-0">
                <input
                  type="text"
                  placeholder="Enter your e-mail address"
                  className="md:w-[300px] h-11 rounded-lg bg-[#EBEBEB] px-4 py-2 text-[#7A7874] text-lg font-normal border border-[#EBEBEB] focus:border-[#E07643] focus:outline-none"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                <button type="submit" className="md:w-32 h-11 rounded-lg bg-[#282828] text-[#FFF] text-lg font-normal" disabled={isSending}>
                  {isSending ? "Sending..." : "Send Invite"}
                </button>
              </form>
              <div className="flex justify-center md:justify-start">
                <p className="text-[12px] md:text-sm font-normal text-[#7A7874] pt-1">Early bird gets the worm. Early adopter gets the whole garden. 😉</p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <ComingSoon />
          </div>
        </div>
      </div>
    </div>

  );
};

export default App;
